import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ApiUser from '../../api/user';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { setLocalStorage } from '../../utils/localStorage';

const LogoutPage = () => {
    // ใช้ useLocation เพื่อดึง URL ปัจจุบัน

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.user);




    useEffect(() => {
        history.push('/')
    }, []);



    return (
        <div style={{ paddingTop: 120, paddingBottom: 100, textAlign: 'center' }}>
            <h1>Logout Success</h1>
            {/* <p><strong>Code:</strong> {code}</p>
            <p><strong>Scope:</strong> {scope}</p>
            <p><strong>Session State:</strong> {sessionState}</p> */}
        </div>
    );
};

export default LogoutPage;

