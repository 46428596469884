import axios from 'axios';
import { Component } from 'react';
import { setHeaderAuth, setHeaderIPv4 } from '../utils';

class ApiUser extends Component {
  static login = async (data) => {
    await setHeaderIPv4();
    const result = await axios({
      url: `auth/login`,
      method: 'post',
      data: data,
    });
    return result;
  };

 static loginV2 = async (data) => {
    await setHeaderIPv4();
    const result = await axios({
      url: `auth/loginV2`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static register = async (data) => {
    const result = await axios({
      url: `auth/register`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static checkname = async (name) => {
    const result = await axios({
      url: `auth/checkname/${name}`,
      method: 'get',
    });
    return result;
  };

  static checknameV2 = async (name) => {
    const result = await axios({
      url: `auth/checknameV2/${name}`,
      method: 'get',
    });
    return result;
  };

  static updateFirst = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `auth`,
      method: 'put',
      data: data,
    });
    return result;
  };

  static uploadImage = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `auth/uploadImage`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static lazorCode = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `auth/verifyIdCard`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static verifyEmail = async (data) => {
    const result = await axios({
      url: `auth/verifyEmail`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static verifyEmailIsExists = async (data) => {
    const result = await axios({
      url: `auth/verifyEmailIsExists`,
      method: 'post',
      data: data,
    });
    return result;
  };


  static verifyEmailIsExistsOTP = async (data) => {
    const result = await axios({
      url: `auth/verifyEmailIsExistsOTP`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static me = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `auth/me`,
      method: 'get',
    });
    return result;
  };
  static logout = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `auth/logout`,
      method: 'get',
    });
    return result;
  };
}

export default ApiUser;
