import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ApiUser from '../../api/user';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { setLocalStorage } from '../../utils/localStorage';
import { swal } from 'sweetalert2/dist/sweetalert2';

const LoginSuccess = (props) => {
    // ใช้ useLocation เพื่อดึง URL ปัจจุบัน

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.user);


    // ฟังก์ชันสำหรับแยก query parameters
    const getQueryParams = (search) => {
        const params = new URLSearchParams(search);
        return {
            code: params.get('code'),
            scope: params.get('scope'),
            sessionState: params.get('session_state'),
        };
    };



    useEffect(() => {
        // ดึงค่าจาก URL
        login();
    }, []);

    const login = async () => {
        try {
            const { code, scope, sessionState } = getQueryParams(location.search);
            Swal.showLoading();
            const result = await ApiUser.loginV2({
                code: code
            });
            if (result.status === 200) {
                const { data } = result;
                const { user, token } = data;
                dispatch({ type: 'set_user', user: user });
                dispatch({ type: 'set_token', token: token });
                setLocalStorage('token', token);
                if (user.is_first === 1) {
                    history.push('/RegisterName');
                } else {
                    history.push('/');
                }
            }
            Swal.close();
        } catch (error) {
            const { data, status } = error.response;
            await Swal.fire(data.message, '', 'error');
            history.push('/');
        }
    }

    return (
        <div style={{ paddingTop: 120, paddingBottom: 100, textAlign: 'center' }}>
            <h1>Login Success</h1>
            {/* <p><strong>Code:</strong> {code}</p>
            <p><strong>Scope:</strong> {scope}</p>
            <p><strong>Session State:</strong> {sessionState}</p> */}
        </div>
    );
};

export default LoginSuccess;

