// export const WEB_API = 'http://159.65.3.230:3230/api/v1/';
export const WEB_SITE = 'https://www.law.go.th';

export const WEB_API = 'https://www.law.go.th:8443/';
export const WEB_OPEN_ID = 'https://connect.egov.go.th/connect/authorize?response_type=code&client_id=6f6b7ab6-e862-46d2-89b4-142893cd1bc2&redirect_uri=https://www.law.go.th/loginsuccess&scope=openid personal_token citizen_id given_name family_name email phone_number email_require email_verified mobile_require ial_level_require preferred_username'
export const WEB_OPEN_ID_LOGOUT = 'https://connect.egov.go.th/connect/endsession?id_token_hint=&post_logout_redirect_uri=https://www.law.go.th/logout'
export const WEB_OPEN_ID_PROFILE = 'https://connect.egov.go.th/Account/Profile'
export const WEB_OPEN_ID_PASSWORD = 'https://connect.egov.go.th/Account/ChangePassword'
//export const WEB_API = 'http://192.168.72.154:8000/';
//export const WEB_API = 'https://lawtest.egov.go.th/api/v1/';
//export const WEB_API = `http://localhost:4001/`;

//beta
//export const WEB_API = 'http://164.115.77.167:3230/api/v1/';
//export const WEB_SITE = 'https://lawtest.egov.go.th/';

// //beta domain
// export const WEB_API = 'https://lawtest.egov.go.th/api/v1/';

//export const WEB_API = 'https://lawtestbeta.egov.go.th/api/v1/';
